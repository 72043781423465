/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:bb0909be-01e0-43cc-92b5-48e69991be76",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_FeiqrYNGH",
    "aws_user_pools_web_client_id": "7dpmn8aqq0jqv57qi5cptgcmor",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "GIVEN_NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": "8",
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://by27mmix7zgk7ga47753plpyby.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-yuv464hn6fgmjaoavczufrvtgu",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://92b1saul4c.execute-api.eu-central-1.amazonaws.com/develop",
            "region": "eu-central-1"
        },
        {
            "name": "onlineDaysConference",
            "endpoint": "https://5van3nry7d.execute-api.eu-central-1.amazonaws.com/develop",
            "region": "eu-central-1"
        },
        {
            "name": "zoomapi",
            "endpoint": "https://gdthnd1xl6.execute-api.eu-central-1.amazonaws.com/develop",
            "region": "eu-central-1"
        },
        {
            "name": "youtubeApi",
            "endpoint": "https://8fqp4r48c3.execute-api.eu-central-1.amazonaws.com/develop",
            "region": "eu-central-1"
        },
        {
            "name": "teamsapi",
            "endpoint": "https://i1bgz6f2r4.execute-api.eu-central-1.amazonaws.com/develop",
            "region": "eu-central-1"
        },
        {
            "name": "odmeetingsapi",
            "endpoint": "https://3s9e9qbptd.execute-api.eu-central-1.amazonaws.com/develop",
            "region": "eu-central-1"
        }
    ],
    "aws_user_files_s3_bucket": "online-days-storage32102-develop",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
